import Pages from "./Pages";
import "../styles/Services.css";
import ServicesContent from "./PageContent/ServicesContent";
import ServicesContentEs from "./PageContent/ServicesContentEs";

class Services extends Pages{
    render() {
        return(
            <div className="Services">
                {"eng" === localStorage.getItem("language") ? (
                    <ServicesContent/>
                ): "es" === localStorage.getItem("language") ? (
                    <ServicesContentEs/>
                ):(
                    <div>Please select a Language above</div>
                )}
            </div>
        );
    }
}

export default Services;