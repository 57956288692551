import React, { Component } from "react";
import SpinLoader from "../loaders/SpinLoader";
import GlobalError from "./GlobalError";
import "./Navigator.css";

class Navigator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [],
            currentPage: React.cloneElement(props.initialPage, {
                changePage: this.setPage.bind(this),
                startLoader: this.startLoader.bind(this),
                stopLoader: this.stopLoader.bind(this),
                showError: this.showError.bind(this)
            }),
            isLoading: true,
            loaderSize: 90,
            loaderSpeed: 2,
            error: null
        };
    }

    startLoader(size = 90, speed = 2) {
        this.setState({
            isLoading: true,
            loaderSize: size,
            loaderSpeed: speed
        });
    }

    stopLoader() {
        this.setState({
            isLoading: false
        });
    }

    isError() {
        return this.state.error !== null;
    }

    showError(error) {
        this.setState({
            error: error
        });
    }

    clearError() {
        this.setState({
            error: null
        });
    }

    goToInitialPage() {
        this.startLoader();
        this.setState({
            history: [],
            currentPage: React.cloneElement(this.props.initialPage, {
                changePage: this.setPage.bind(this),
                startLoader: this.startLoader.bind(this),
                stopLoader: this.stopLoader.bind(this),
                showError: this.showError.bind(this)
            }),
            isLoading: true
        });
    }

    setPage(page) {
        let newHistory = this.state.history;
        newHistory.push(this.state.currentPage);
        this.setState({
            history: newHistory,
            currentPage: React.cloneElement(page, {
                changePage: this.setPage.bind(this),
                goToInitialPage: this.goToInitialPage.bind(this),
                startLoader: this.startLoader.bind(this),
                stopLoader: this.stopLoader.bind(this),
                showError: this.showError.bind(this)
            }),
            isLoading: true
        });
    }

    goBack() {
        if (this.state.history.length > 0) {
            let lastPage = this.state.history[this.state.history.length - 1];
            let newHistory = this.state.history;
            newHistory.pop();
            this.setState({
                history: newHistory,
                currentPage: lastPage,
                isLoading: true
            });
        }
    }

    render() {
        return (
            <div className="Navigator">
                {this.isError() ? (
                    <GlobalError error={this.state.error} onClose={this.clearError.bind(this)}/>
                ) : this.state.isLoading ? (
                    <SpinLoader size={this.state.loaderSize} speed={this.state.loaderSpeed}/>
                ) : (this.state.history.length > 0) && (
                    <div className="Navigator-button-container">
                        <button className="Navigator-back-button" onClick={this.goBack.bind(this)}>&larr;</button>
                    </div>
                )}
                <div className={"Navigator-screen-container" + (this.state.isLoading || this.isError() ? " hide" : "")}>
                    {this.state.currentPage}
                </div>
            </div>
        );
    }
}

export default Navigator;