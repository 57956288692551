import { Component } from "react";

class ServicesContent extends Component{
    render(){
        return(
            <div>
                <div className="ServicesHead">
                    Our Services
                </div>
                <hr/>
                <div className="ServicesGen">
                    <p>
                        At Andreeva & Gutierrez Law, LLC,  we provide a range of legal services to assist individuals and families in navigating the complex U.S. immigration system. With over 20 years of combined legal experience, our attorneys offer a variety of immigration legal services, as well as assistance with unique and specialized issues.
                    </p>
                    <p>
                        Our firm offers wrap-around services for any ancillary issues, such as dealing with various government agencies (e.g. SSA, DMV, etc), school and student advocacy, legal name and sex changes and more. We are a culturally and LGBTQIA2S+ conscious practice. We speak Spanish, Russian, and English.
                    </p>
                </div>
                <div className="ServicesOpt">
                    <div className="SO1">
                        <div className="OptionHead">Consultations</div>
                        <div className="OptionBody">
                            A consultation is a necessary first step when our attorneys get an opportunity to learn more about you and your unique circumstances and develop a case plan that meets your needs. Schedule your in-person, phone or video consultation with our attorneys today.
                        </div>
                    </div>
                    <div className="SO2">
                        <div className="OptionHead">FOIA Requests and Background Checks</div>
                        <div className="OptionBody">
                            Request for files and documents from various government agencies, such as ICE, CBP, USCIS, SSA, courts, and police departments around the United States.
                        </div>
                    </div>
                    <div className="SO3">
                        <div className="OptionHead">Citizenship and Citizenship Documents</div>
                        <div className="OptionBody">
                            Representation of eligible Lawful Permanent Residents on the path to U.S. citizenship through naturalization or alternative avenues like acquisition, derivation, and consular reports of birth abroad. Citizenship interview and civics test preparation, optional in-person interview representation. Citizenship document replacement and US passport applications.
                        </div>
                    </div>
                    <div className="SO4">
                        <div className="OptionHead">Residency</div>
                        <div className="OptionBody">
                            Renewal and Replacement of Lawful Permanent Residency cards (“green cards”), including removal of conditions on residency, and adjustment of status to become a resident.
                        </div>
                    </div>
                    <div className="SO5">
                        <div className="OptionHead">Family Petitions</div>
                        <div className="OptionBody">
                            Petitions for U.S. citizens and Lawful Permanent Residents who are sponsoring eligible family members for immigrant visas, including relatives residing within the U.S. and abroad.
                        </div>
                    </div>
                    <div className="SO6">
                        <div className="OptionHead">Consular Processing</div>
                        <div className="OptionBody">
                            Assistance to individuals who are outside of the U.S. with obtaining immigrant and nonimmigrant visas at U.S. consulates abroad, including application, document collection, and interview preparation.
                        </div>
                    </div>
                    <div className="SO7">
                        <div className="OptionHead">U visas, T visas, and VAWA</div>
                        <div className="OptionBody">
                            Our attorneys support immigrant survivors of crimes, such as domestic violence, sexual assault, and human trafficking, in applications for temporary legal status, lawful permanent residency, and citizenship.
                        </div>
                    </div>
                    <div className="SO8">
                        <div className="OptionHead">Special Immigrant Juvenile Status (SIJS)</div>
                        <div className="OptionBody">
                            Temporary status and adjustment for youth under the age of 21 who have been abused, abandoned, or neglected by one or both of their parents.
                        </div>
                    </div>
                    <div className="SO9">
                        <div className="OptionHead">Deferred Action for Childhood Arrivals (DACA) Initial and Renewal Applications</div>
                        <div className="OptionBody">
                            Applications for individuals who entered the U.S. as children to obtain protection from deportation and work authorization under the DACA program.
                        </div>
                    </div>
                    <div className="SO10">
                        <div className="OptionHead">Military Parole In Place</div>
                        <div className="OptionBody">
                            Assistance for military families to apply for Military Parole In Place and further related processing.
                        </div>
                    </div>
                    <div className="SO11">
                        <div className="OptionHead">Labor-based Deferred Action</div>
                        <div className="OptionBody">
                            Temporary protection and work authorizations for immigrants who have been victims of employment law violations.
                        </div>
                    </div>
                    <div className="SO12">
                        <div className="OptionHead">Travel Documents</div>
                        <div className="OptionBody">
                            Documents that permit immigrants to return to the U.S. after travel abroad, including advanced parole for DACA recipients, emergency advance parole, and re-entry permits.
                        </div>
                    </div>
                    <div className="SO13">
                        <div className="OptionHead">Fiancé Visas</div>
                        <div className="OptionBody">
                           Assistance to U.S. citizens sponsoring their fiancé(e)s residing abroad.
                        </div>
                    </div>
                    <div className="SO14">
                        <div className="OptionHead">Humanitarian Program Visas and Adjustment</div>
                        <div className="OptionBody">
                            Temporary Protected Status (TPS), medical and humanitarian parole, Cuban Adjustment Act applications, and any other qualifying humanitarian program applications and renewals.
                        </div>
                    </div>
                    <div className="SO15">
                        <div className="OptionHead">Political Asylum</div>
                        <div className="OptionBody">
                            Political asylum applications for survivors of persecution that took place in the immigrant’s home country.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServicesContent;