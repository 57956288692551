import { Component } from "react";

class AboutUsContent extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="NonMobileContent">
                <div className="AboutUsLabel">Our Attorneys</div>
                <hr className="spacerAU"/>
                <ul className="Column">
                    <li className="PictureContainer">
                        <img src={this.props.nadia} width='100%'/>
                        <div className="AboutName">Nadezhda (Nadia) Andreeva, J.D.</div>
                    </li>
                    <li className="Bio">
                        <div>
                            <p>Nadia graduated from Southern Oregon University, summa cum laude, with a Bachelor’s in International Studies and the Spanish Language. In 2017, she graduated from the University of Arizona Law School. Throughout her law school experience, Nadia interned with the local juvenile, federal, and immigration courts. By devoting these formative years to community justice clinics, she gained valuable experience working closely with judges, federal agencies, detention centers, and clients.</p>
                            <p>After graduation, Nadia worked with the Florence Project children’s team in Tucson, Arizona. There, she represented unaccompanied immigrant children in court, assisting with their asylum and special immigrant juvenile matters.</p>
                            <p>For the past 5 years, Nadia had worked as a Staff Immigration Attorney at the Center for Nonprofit Legal Services, representing immigrants with diverse cultural backgrounds in a variety of immigration applications. Nadia specializes in trauma-informed, wrap-around, client centered representation, as well as excellent legal research and writing skills.</p>
                            <p>As a Russian immigrant, Nadia learned first-hand how difficult it is to navigate the US legal and immigration systems. She is passionate about making the legal system accessible to her clients, and advocating for the desired and just outcome. She speaks Russian and Spanish fluently. Nadia enjoys hiking, camping, traveling, gardening, trying new recipes, and spending a lot of time with her family and pets.</p>
                            <p>Nadia is licensed to practice law in Oregon and New York. She is an American Immigration Lawyers Association member.</p>
                        </div>
                    </li>
                </ul>
                <hr className="spacerAU"/>
                <ul className="Column">
                    <li className="PictureContainer">
                        <img src={this.props.laura} width='100%'/>
                        <div className="AboutName">Laura Gutierrez, J.D., LL.M.</div>
                    </li>
                    <li className="Bio">
                        <p>Laura Gutierrez graduated from UC Santa Barbara in 2001 and Southwestern Law School in 2004. In 2010 she obtained her Masters of Law from Vermont Law School. During law school she represented Los Angeles residents in a variety of areas such as eviction defense, public benefits loss, homelessness, and access to justice.</p>
                        <p>Laura started her legal career as a civil rights attorney engaging in impact litigation on behalf of incarcerated individuals, and advocating for victims of police misconduct. She also worked as a public defender, as a labor rights advocate at the Northwest Forest Worker Center, and as a Victim Advocate at the District Attorney’s Office.</p>
                        <p>As the Supervising Attorney for the Center for Nonprofit Legal Services Immigration Law Project from 2015 to 2024, Laura represented immigrants in a variety of immigration applications, prioritizing advocacy for immigrant survivors of crimes. Laura spearheaded the effort to create and integrate systems in Jackson County to support unaccompanied minors by eliminating barriers to immigration representation and access to educational and social services. Laura speaks Spanish fluently and is trained in trauma-informed, client-centered representation.</p>
                        <p>Laura and her children volunteer weekly alongside Jobs with Justice, providing home-made food to the unhoused and food insecure community, and she serves on the Board of Directors for Rogue Food Unites. In her free time she loves to run and bike in the woods with her kids and dog, laugh with friends, and make lopsided mugs at a ceramics studio.</p>
                        <p className="singleSpace1">Licensed to practice in Oregon and California</p>
                        <p className="singleSpace">American Immigration Lawyers Association (AILA) Member, 2015-Present</p>
                        <p className="singleSpace">Oregon Liaison, American Immigration Council 5th Grade Creative Writing Contest</p>
                        <p className="singleSpace">AILA Nonprofit Working Group Co-Liason, 2019-2024</p>
                        <p className="singleSpace">AILA NW Conference Co-Coordinator 2020</p>
                    </li>
                </ul>
            </div>
        )
    }
}

export default AboutUsContent;