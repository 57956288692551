import { Component } from "react";

class EmailField extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: null
        }
    }

    updateEmail(event){
        this.setState({
            email: event.target.value
        });
        this.props.onChange(event.target.value);
    }

    render(){
        return(
            <div className="EmailFieldContainer">
                {"eng" === localStorage.getItem("language") ? (
                    <label className="FieldLabel">Email</label>
                ): "es" === localStorage.getItem("language") ? (
                    <label className="FieldLabel">Correo electrónico</label>
                ):(
                    <div>Please select a Language above</div>
                )}
                <input className="EmailField" id="email" onChange={this.updateEmail.bind(this)}/>
            </div>
        );
    }
}

export default EmailField;