import { Component } from "react";

class Navbar extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                {"eng" === this.props.language ? (
                    <div>
                        <ul className='navbar'>
                        <li className='navbar-option'><a href="/">Home</a></li>
                        <li className='navbar-option'><a href="/about-us">About Us</a></li>
                        <li className='navbar-option'><a href="/services">Services</a></li>
                        <li className='navbar-option'><a href="/contact-us">Contact Us</a></li>
                        <li className='navbar-option-button'><div className='Navbutton'><a href='https://secure.lawpay.com/pages/andreevaandgutierrezlawllc/operating'>Make a Payment</a></div></li>
                    </ul>
                    </div>
                ): "es" === this.props.language ? (
                    <div>
                        <ul className='navbar'>
                            <li className='navbar-option'><a href="/">Inicio</a></li>
                            <li className='navbar-option'><a href="/about-us">Sobre nosotras</a></li>
                            <li className='navbar-option'><a href="/services">Nuestros servicios</a></li>
                            <li className='navbar-option'><a href="/contact-us">Contacto con nosotras</a></li>
                            <li className='navbar-option-button'><div className='Navbutton'><a href='https://secure.lawpay.com/pages/andreevaandgutierrezlawllc/operating-sp'>Hacer un pago</a></div></li>
                        </ul>
                    </div>
                ) : (
                    <div>Please select a Language from above{console.log(this.props.language)}</div>
                )}
            </div>
        )
    }
}

export default Navbar;