import { Component } from "react";

class PhoneField extends Component{
    constructor(props){
        super(props);
        this.state = {
            phone: null
        }
    }

    updatePhone(event){
        this.setState({
            phone: event.target.value
        });
        this.props.onChange(event.target.value);
    }

    render() {
        return(
            <div className="PhoneFieldContainer">
                {"eng" === localStorage.getItem("language") ? (
                    <label className="FieldLabel">Phone*</label>
                ): "es" === localStorage.getItem("language") ? (
                    <label className="FieldLabel">Teléfono*</label>
                ):(
                    <div>Please select a Language above</div>
                )}
                <input className="PhoneField" id="phone" onChange={this.updatePhone.bind(this)}/>
            </div>
        );
    }
}

export default PhoneField;