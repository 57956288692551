import { Component } from "react";

class HomeContent extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <div>
                <div className="MissionLabel">Energy, Empathy & Expertise</div>
                <img className="homeLogo" src={this.props.logo} width= "10%"/>
                <hr/>
                <p className="MissionText">
                Andreeva & Gutierrez Law represents clients in navigating a variety of legal matters, specializing in client-centered representation, that is trauma-informed. We take the time to learn about our client’s unique circumstances to craft an individualized case plan that maximizes a positive outcome. With over 25 years of legal experience, we bring energy, empathy, and expertise to meet our client’s legal needs.
                </p>
                <p className="MissionText">
                Located in Medford, Oregon, Andreeva & Gutierrez Law attorneys are licensed to practice law in Oregon, California, and New York, and admitted to practice in state and federal courts. Our firm lawyers are American Immigration Lawyers Association Members.
                </p>
            </div>
        )
    }
}

export default HomeContent;