import { Component } from "react";

class HomeContentEs extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <div>
                <div className="MissionLabel">Energía, empatía y experiencia</div>
                <img className="homeLogo" src={this.props.logo} width= "10%"/>
                <hr/>
                <p className="MissionText">
                Andreeva & Gutierrez Law representa a clientes en una variedad de asuntos legales, especializándose en representación centrada en el cliente, informada sobre el trauma. Nos tomamos el tiempo para conocer las circunstancias únicas de nuestros clientes para elaborar un plan de caso individualizado que maximice un resultado positivo. Con más de 25 años de experiencia legal, aportamos energía, empatía y experiencia para satisfacer las necesidades legales de nuestros clientes.
                </p>
                <p className="MissionText">
                Ubicadas en Medford, Oregón, las abogadas de Andreeva & Gutierrez Law tienen licencia para practicar las leyes en Oregón, California y Nueva York, y están autorizadas para ejercer en tribunales estatales y federales. Las abogadas de nuestra firma son miembras de la Asociación Estadounidense de Abogados de Inmigración (AILA).
                </p>
            </div>
        )
    }
}

export default HomeContentEs;