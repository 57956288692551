import { Component } from "react";

class Disclaimer  extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                {"eng" === this.props.language ? (
                    <div>
                        <div className="copyright">&copy; {(new Date()).getFullYear()} by Andreeva & Gutierrez Law LLC</div>
                        <div className='legal-note'>
                        Please note by providing your phone number to Andreeva & Gutierrez Law LLC, you agree that we may send you text messages. Message and data rates may apply. Message frequency will vary. You can request to opt - out of future messaging or request more information.   
                        <br/><br/>
                        No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
                        </div>
                    </div>
                ) : "es" === this.props.language ? (
                    <div>
                        <div className="copyright">&copy; {(new Date()).getFullYear()} por Andreeva & Gutierrez Law LLC</div>
                        <div className='legal-note'>
                            Tenga en cuenta que al proporcionar su número de teléfono a Andreeva & Gutierrez Law LLC, acepta que podemos enviarle mensajes de texto. Es posible que se apliquen tarifas de mensajes y datos. La frecuencia de los mensajes variará. Puede solicitar la exclusión voluntaria de mensajes futuros o solicitar más información.
                            <br/><br/>
                            No se compartirá ninguna información móvil con terceros/afiliados con fines de marketing/promoción. Todas las categorías anteriores excluyen los datos de aceptación y el consentimiento del originador de mensajes de texto; Esta información no será compartida con terceros.
                        </div>
                    </div>
                ) : (
                    <div>Please select a language above.{console.log(this.props.language)}</div>
                )}
            </div>
        )
    }
}

export default Disclaimer;