import { Component } from "react";

class ServicesContentEs extends Component{
    render(){
        return(
            <div>
                <div className="ServicesHead">
                    Nuestros servicios
                </div>
                <hr/>
                <div className="ServicesGen">
                    <p>
                        En Andreeva & Gutierrez Law, LLC, brindamos una variedad de servicios legales para ayudar a personas y familias a navegar por el complejo sistema de inmigración de los EE. UU. Con más de 20 años de experiencia legal combinada, nuestro demandante ofrece una variedad de servicios legales de inmigración, así como asistencia con problemas únicos y especializados.
                    </p>
                    <p>
                        Nuestra firma ofrece servicios integrales para cualquier asunto auxiliar, como diversos asuntos de agencias gubernamentales (por ejemplo, SSA, DMV, etc.), defensa de escuelas y estudiantes, cambios legales de nombre y sexo y más. Somos una práctica culturalmente consciente y LGBTQIA2S+. Hablamos español, ruso e inglés.
                    </p>
                </div>
                <div className="ServicesOpt">
                    <div className="SO1">
                        <div className="OptionHead">Consultas</div>
                        <div className="OptionBody">
                            Una consulta es un primer paso necesario cuando nuestro equipo tiene la oportunidad de aprender más sobre usted y sus circunstancias únicas y desarrollar un plan de caso que satisfaga sus necesidades. Programe su consulta en persona, por teléfono o por video con nuestro equipo hoy.
                        </div>
                    </div>
                    <div className="SO2">
                        <div className="OptionHead">Solicitudes de archivo migratorio y verificación de antecedentes</div>
                        <div className="OptionBody">
                            Solicitud de archivos y documentos de diversas agencias gubernamentales, como ICE, CBP, USCIS, SSA y tribunales y departamentos de policía de todo Estados Unidos.
                        </div>
                    </div>
                    <div className="SO3">
                        <div className="OptionHead">Documentos de ciudadanía y ciudadanía</div>
                        <div className="OptionBody">
                            Representación de residentes permanentes elegibles para convertirse en ciudadanos estadounidenses a través de naturalización o vías alternativas como adquisición, derivación e informes consulares de nacimiento en el extranjero. Entrevista de ciudadanía y preparación para exámenes de educación cívica, representación de entrevista en persona opcional y solicitudes de pasaporte estadounidense.
                        </div>
                    </div>
                    <div className="SO4">
                        <div className="OptionHead">Residencia</div>
                        <div className="OptionBody">
                            Renovación y reemplazo de tarjetas de residencia permanente(“micas”), incluida la remocion de condiciones de residencia y el ajuste de estatus para convertirse en residente.
                        </div>
                    </div>
                    <div className="SO5">
                        <div className="OptionHead">Peticiones familiares</div>
                        <div className="OptionBody">
                            Peticiones para ciudadanos estadounidenses y residentes permanentes legales que patrocinan a familiares elegibles para visas de inmigrante, incluidos familiares que residen dentro de los EE. UU. y en el extranjero.                        
                        </div>
                    </div>
                    <div className="SO6">
                        <div className="OptionHead">Procesamiento consular</div>
                        <div className="OptionBody">
                            Nuestra firma ayuda a personas que se encuentran fuera de los EE. UU. a obtener visas de inmigrante y no inmigrante en los consulados de los EE. UU. en el extranjero, incluida asistencia con la solicitud, recopilación de documentos y preparación de entrevistas.                        
                        </div>
                    </div>
                    <div className="SO7">
                        <div className="OptionHead">Visas U, visas T y VAWA</div>
                        <div className="OptionBody">
                            Nuestras abogadas apoyan a inmigrantes sobrevivientes de crimenes y a sus familiares, incluida la violencia doméstica, la agresión sexual y la trata de personas, en solicitudes de estatus legal temporal, residencia permanente y ciudadanía.
                        </div>
                    </div>
                    <div className="SO8">
                        <div className="OptionHead">Estatus Especial de Inmigrante Juvenil (SIJS)</div>
                        <div className="OptionBody">
                            Estatus protegido temporal y adjusto de estatus para jóvenes menores de 21 años que han sido abusados, abandonados o descuidados por uno o ambos de sus padres.
                        </div>
                    </div>
                    <div className="SO9">
                        <div className="OptionHead">Solicitudes iniciales y de renovación de Acción Diferida para los Llegados en la Infancia (DACA)</div>
                        <div className="OptionBody">
                            Solicitudes para las personas que ingresaron a los EE. UU. cuando eran niños a obtener protección contra la deportación y autorización de trabajo bajo el programa DACA.
                        </div>
                    </div>
                    <div className="SO10">
                        <div className="OptionHead">PIP para familiares de militares</div>
                        <div className="OptionBody">
                            Solicitudes para las familias de militares a permiso temporal permanecer en los EE.UU. y procesos relacionados adicionales.
                        </div>
                    </div>
                    <div className="SO11">
                        <div className="OptionHead">Acción diferida basada en mano de obra</div>
                        <div className="OptionBody">
                            Auyda a víctimas de violaciones de las leyes laborales a obtener protección temporal contra la deportación y una autorización de trabajo.
                        </div>
                    </div>
                    <div className="SO12">
                        <div className="OptionHead">Documentos de viaje</div>
                        <div className="OptionBody">
                            Solicitudes para permisos para regresar a los EE. UU. después de viajar al extranjero, incluido permisos para beneficiarios de DACA, permisos de emergencia y permisos de reingreso.
                        </div>
                    </div>
                    <div className="SO13">
                        <div className="OptionHead">Visas de prometido</div>
                        <div className="OptionBody">
                            Asistencia a ciudadanos estadounidenses que patrocinan a sus prometidos residentes en el extranjero.
                        </div>
                    </div>
                    <div className="SO14">
                        <div className="OptionHead">Visas y Ajustes del Programa Humanitario</div>
                        <div className="OptionBody">
                            El Estatus de Protección Temporal (TPS), el ingreso medico y humanitario, las solicitudes de la Ley de Ajuste Cubano y cualquier otra solicitud y renovación de programas humanitarios que califiquen.
                        </div>
                    </div>
                    <div className="SO15">
                        <div className="OptionHead">Asilo político</div>
                        <div className="OptionBody">
                            Solicitudes de asilo politico personas que han sufrido persecución en su país de origen.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServicesContentEs;