import { Component } from "react";

class ContactInfoMobile extends Component{
    render() {
        return(
            <div className="ContactInfoMobile">
                <div className="ContactName">Andreeva & Gutierrez Law, LLC</div>
                <div className="ContactAddress">2 N Oakdale Ave</div>
                <div className="ContactAddress">Medford, OR 97501</div>
                <div className="ContactPhone">(458) 250-4466</div>
                <div className="ContactEmail">nadia@oregon-law.net</div>
            </div>
        );
    }
}

export default ContactInfoMobile;