import { Component } from "react";
import emailjs from "@emailjs/browser";

class Email extends Component{
    constructor(props){
        super(props)
    }

    sendEmail(data){
        let templateParams = {
            to_name: "Laura and Nadia",
            from_name: "Oregon-Law",
            name: data.name,
            email: data.email,
            phone: data.phone,
            message: data.message
        }

        emailjs.send("service_2m9zy37", "template_3wj6jxb", templateParams, {publicKey: 'UVTzjd17xw71Rs-tf'}).then(
            (response) => {
                console.log('Success!', response.status, response.text);
            },
            (error) => {
                console.log('Failed...', error);
            },
        );
    }
}

export default Email;