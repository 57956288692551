import './styles/App.css';
import { Component } from 'react';
import Navbar from "./Navbar";
import Disclaimer from './Disclaimer';
import About from './Pages/AboutUs';
import Home from './Pages/Home';
import Services from './Pages/Services';
import ContactUs from './Pages/ContactUs';
import logo from './images/Logo_Flush Left.png';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: ""
    }
    console.log(localStorage.getItem("language"))
    if(localStorage.getItem("language") !== "eng" && localStorage.getItem("language") !== "es"){
      localStorage.setItem("language", "eng");
    }
  }

  componentDidMount() {
    let path = window.location.pathname;
    if ("/" === path.charAt(0)) {
      path = path.substring(1);
    }
    if (path !== this.state.path) {
      this.setState({ path, isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  }

  swapToEng(){
    this.setState({language: "eng"})
    localStorage.setItem("language", "eng");
  }

  swapToEs(){
    this.setState({language: "es"})
    localStorage.setItem("language", "es");
  }

  render() {
    return(
      <div className="App">
        <div className='AppContainer'>
          <div className="home-header">
            <ul className='LogoAndLang'>
              <img src={logo} className='LogoImg' width='50%' height='50%'/>
              <div className='DropdownContent' id="dropdown">
                <div className='SelectedLanguage' id="lang">
                  {"eng" === localStorage.getItem("language") ? (<div>English</div>) : "es" === localStorage.getItem("language") ? (<div>Español</div>) : (<div>No Language Found</div>)}
                </div>
                <ul className='LanguageContainer'>
                  <li className='LanguageOption'><a href={window.location.path} className="us" onClick={this.swapToEng.bind(this)}>English</a></li>
                  <li className='LanguageOption'><a href={window.location.path} className="es" onClick={this.swapToEs.bind(this)}>Español</a></li>
                </ul>
              </div>
            </ul>
            <hr/>
            <div className='navbar-container'>
              <Navbar language={localStorage.getItem("language")}/>
            </div>
          </div>
          <div className="home-body">
            <div className='PageContainer'>
              {"" === this.state.path ? (
                <Home />
              ) : "about-us" === this.state.path ? (
                <About />
              ) : "services" === this.state.path ? (
                <Services />
              ) : "contact-us" === this.state.path ? (
                <ContactUs />
              ) : (
                <div>
                  Sorry, we don't recognize the <pre>{window.location.pathname}</pre> page. Please see the menu above to select a page.
                </div>
              )}
            </div>
          </div>
          <div className="home-footer">
            <Disclaimer language={localStorage.getItem("language")}/>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
