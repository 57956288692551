import Pages from "./Pages";
import "../styles/Home.css";
import homepic from '../images/Home Page Horizontal1.jpg';
import homepic2 from '../images/Home Page Horizontal2.jpg';
import homepic3 from "../images/Horizontal Home Page No Door Knob.jpg";
import logo from "../images/LogoColor.png";
import HomeContent from "./PageContent/HomeContent";
import HomeContentEs from "./PageContent/HomeContentEs";

class Home extends Pages{
    render() {
        return(
            <div className="Home">
                <div className="CoverPic">
                    <img src={homepic3} width='80%'/>
                    
                </div>
                <div className="Mission">
                    {"eng" === localStorage.getItem("language") ? (
                            <HomeContent logo={logo}/>
                    ): "es" === localStorage.getItem("language") ? (
                            <HomeContentEs logo={logo}/>
                    ) : (
                        <div>Please select a Language above</div>
                    )}
                </div>
            </div>
        );
    }
}

export default Home;