import { Component } from "react";

class MessageField extends Component{
    constructor(props){
        super(props);
        this.state = {
            message: null
        }
    }

    updateMessage(event){
        this.setState({
            message: event.target.value
        })
        this.props.onChange(event.target.value);
    }

    render(){
        return(
            <div className="MessageFieldContainer">
                {"eng" === localStorage.getItem("language") ? (
                    <label className="FieldLabel">Message</label>
                ): "es" === localStorage.getItem("language") ? (
                    <label className="FieldLabel">Mensaje</label>
                ):(
                    <div>Please select a Language above</div>
                )}
                <textarea className="MessageField" id="message" onChange={this.updateMessage.bind(this)}/>
            </div>
        );
    }
}

export default MessageField;