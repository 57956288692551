import Pages from "./Pages";
import "../styles/AboutUs.css";
import nadia from '../images/Bio Nadia.jpg';
import laura from '../images/Bio Laura.jpg';
import AboutUsContent from "./PageContent/AboutUsContent";
import AboutUsContentMobile from "./PageContent/AboutUsContentMobile";
import AboutUsContentEs from "./PageContent/AboutUsContentEs";
import AboutUsContentEsMobile from "./PageContent/AboutUsContentEsMobile";

class About extends Pages{
    render() {
        return(
            <div className="AboutUs">
                {"eng" === localStorage.getItem("language") ? (
                    <div>
                        <AboutUsContent nadia={nadia} laura={laura}/>
                        <AboutUsContentMobile nadia={nadia} laura={laura}/>
                    </div>
                ): "es" === localStorage.getItem("language") ? (
                    <div>
                        <AboutUsContentEs nadia={nadia} laura={laura}/>
                        <AboutUsContentEsMobile nadia={nadia} laura={laura}/>
                    </div>
                ):(
                    <div>Please select a Language above</div>
                )}
            </div>
        );
    }
}

export default About;