import { Component } from "react";

class NameField extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: null
        }
    }

    updateName(event){
        this.setState({
            name: event.target.value
        });
        this.props.onChange(event.target.value);
    }

    render(){
        return(
            <div className="NameFieldContainer">
                {"eng" === localStorage.getItem("language") ? (
                    <label className="FieldLabel">Name*</label>
                ): "es" === localStorage.getItem("language") ? (
                    <label className="FieldLabel">Nombre*</label>
                ):(
                    <div>Please select a Language above</div>
                )}
                <input className="NameField" id="name" onChange={this.updateName.bind(this)}/>
            </div>
        );
    }
}

export default NameField;