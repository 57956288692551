import { Component } from "react";

class AboutUsContentEs extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="NonMobileContent">
                <div className="AboutUsLabel">Nuestras abogadas</div>
                <hr className="spacerAU"/>
                <ul className="Column">
                    <li className="PictureContainer">
                        <img src={this.props.nadia} width='100%'/>
                        <div className="AboutName">Nadezhda (Nadia) Andreeva, J.D.</div>
                    </li>
                    <li className="Bio">
                        <div>
                            <p>Nadia se graduó summa cum laude de la Southern Oregon University con una licenciatura en Estudios Internacionales y Lengua Española. En 2017, se graduó de la Facultad de Derecho de la Universidad de Arizona. A lo largo de su experiencia en la facultad de derecho, Nadia realizó prácticas en los tribunales estatales, federales y de inmigración. Al dedicar estos años de formación a clínicas de justicia comunitaria, adquirió una valiosa experiencia trabajando en colaboración con jueces, agencias federales, centros de detención y clientes.</p>
                            <p>Después de graduarse, Nadia trabajó con el equipo infantil del Proyecto Florence en Tucson, Arizona. Allí, representó a niños inmigrantes no acompañados en los tribunales, ayudándolos con su asilo y asuntos especiales para inmigrantes juveniles.</p>
                            <p>Durante los últimos 5 años, Nadia había trabajado como abogada de inmigración en el Centro de Servicios Legales sin Fines de Lucro, representando a inmigrantes con diversos orígenes culturales en una variedad de solicitudes de inmigración. Nadia se especializa en representación integral, centrada en el cliente y basada en el trauma, así como en excelentes habilidades de redacción e investigación jurídica.</p>
                            <p>Como inmigrante rusa, Nadia aprendió de primera mano lo difícil que es navegar por los sistemas legales y de inmigración de Estados Unidos. Le apasiona hacer que el sistema legal sea accesible para sus clientes y abogar por el resultado justo y deseado. Habla ruso y español con fluidez. A Nadia le gusta hacer caminatas, acampar, viajar, hacer jardinería, probar nuevas recetas y pasar mucho tiempo con su familia y sus mascotas.</p>
                            <p>Nadia tiene licencia para practicar las leyes en Oregón y Nueva York. Es miembro de la Asociación Estadounidense de Abogados de Inmigración.</p>
                        </div>
                    </li>
                </ul>
                <hr className="spacerAU"/>
                <ul className="Column">
                    <li className="PictureContainer">
                        <img src={this.props.laura} width='100%'/>
                        <div className="AboutName">Laura Gutierrez, J.D., LL.M.</div>
                    </li>
                    <li className="Bio">
                        <p>Laura Gutierrez se graduó de la Universidad de California en Santa Bárbara en 2001 y de la Facultad de Derecho de Southwestern en 2004. En 2010 obtuvo su Maestría en Derecho de la Facultad de Derecho de Vermont. Durante sus estudios en la facultad de derecho, representó a los residentes de Los Ángeles en diversas áreas, como defensa de desalojos, pérdida de beneficios públicos, falta de vivienda y acceso a la justicia.</p>
                        <p>Laura comenzó su carrera legal como abogada de derechos civiles, participando en litigios de impacto en nombre de personas encarceladas y defendiendo a las víctimas de mala conducta policial. También trabajó como defensora pública, defensora de los derechos laborales en el Centro de Trabajadores Forestales del Noroeste y como defensora de víctimas en la Fiscalía del Distrito.</p>
                        <p>Como abogada supervisora ​​del Proyecto de Derecho de Inmigración del Centro de Servicios Legales sin Fines de Lucro de 2015 a 2024, Laura representó a inmigrantes en una variedad de solicitudes de inmigración, priorizando la defensa de los inmigrantes sobrevivientes de crímenes. Laura encabezó el esfuerzo por crear e integrar sistemas en el condado de Jackson para apoyar a los menores no acompañados eliminando las barreras a la representación migratoria y el acceso a los servicios educativos y sociales. Laura habla español con fluidez y está capacitada en representación centrada en el cliente e informada sobre el trauma.</p>
                        <p>Laura y sus hijos se ofrecen como voluntarios semanalmente junto con Jobs with Justice, proporcionando comida casera a la comunidad sin hogar y con inseguridad alimentaria, y es miembra de la Junta Directiva de Rogue Food Unites. En su tiempo libre, le encanta correr y andar en bicicleta por el bosque con sus hijos y su perro, reír con amigos y hacer tazas torcidas en un estudio de cerámica.</p>
                        <p className="singleSpace1">Licenciada para practicar las leyes en Oregón y California</p>
                        <p className="singleSpace">Miembra de la Asociación Estadounidense de Abogados de Inmigración (AILA), 2015-presente</p>
                        <p className="singleSpace">Enlace de Oregón, Concurso de escritura creativa de quinto grado del Consejo Estadounidense de Inmigración</p>
                        <p className="singleSpace">Coenlace del grupo de trabajo sin fines de lucro de AILA, 2019-2024</p>
                        <p className="singleSpace">Coordinador de la conferencia AILA NW 2020</p>
                    </li>
                </ul>
            </div>
        )
    }
}

export default AboutUsContentEs;