import Pages from "./Pages";
import "../styles/ContactUs.css";
import contact from '../images/Outreach Page.jpg';
import Navigator from "../Utilities/Navigator";
import ContactForm from "../ContactForm/ContactForm";
import ContactInfo from ".//PageContent/ContactInfo";
import ContactInfoMobile from ".//PageContent/ContactInfoMobile";

class ContactUs extends Pages{
    render() {
        return(
            <div className="ContactUs">
                {"eng" === localStorage.getItem("language") ? (
                    <div className="ContactHead">Contact Us</div>
                ): "es" === localStorage.getItem("language") ? (
                    <div className="ContactHead">Contacto con nosotras</div>
                ):(
                    <div>Please select a Language above</div>
                )}
                <hr className="spacerCU"/>
                <ul className="ContactColumn">
                    <li className="ContactFormContainer">
                        <ContactForm/>
                        <ContactInfo/>
                    </li>
                    <li className="ContactInfoContainer">
                        <img className="ContactImg" src={contact}/>
                        <ContactInfoMobile/>
                    </li>
                </ul>
            </div>
        );
    }
}

export default ContactUs;